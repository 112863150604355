import useAxios from './useAxios';
import { useEffect, useState } from 'react';

export interface IUser {
  id: string;
  status: string;
  created: string;
  lastUpdated: string;
  lastLogin: null | string;
  passwordChanged: null | string;
  assignments?: string[];
  profile: {
    firstName: string;
    lastName: string;
    mobilePhone: null | string;
    login: string;
    email: string;
  };
  assignmentEntity?: any[];
}

interface ISearchAssignments {
  applicationId: string;
  assignments: string[];
  cb?: (data: any) => void;
  cursor?: any;
  limit: number;
  offset: number;
}

interface IGetAllWithPagination {
  applicationId: string;
  limit: number;
  offset: number;
  search?: string;
  keySearch?: string;
  after?: string;
  cb?: (data: any) => void;
}

interface ISearchUsersAndAssignments {
  applicationId: string;
  search: string;
  assignments: string[];
  after?: string;
  limit?: number;
  next?: string;
  cb?: (data: any) => void;
}

export default (): {
  data: any;
  error: any;
  assignments: any;
  loading: boolean;
  loadingAssignment: boolean;
  getAll: (applicationId: string) => void;
  getAllWithCursor: (applicationId: string, cursor: string) => void;
  cancel: () => void;
  get: (applicationId: string, userId: string) => void;
  set: (applicationId: string, userId: string, user: IUser) => void;
  getAssignments: (applicationId: string, userId: string) => void;
  addAssignment: (applicationId: string, userId: string, assignmentCode: string) => Promise<void>;
  addUsersToAssignment: (
    applicationId: string,
    userIds: string[],
    assignmentCode: string,
    userType?: string,
  ) => Promise<void>;
  removeAssignment: (applicationId: string, userId: string, assignmentCode: string) => Promise<void>;
  searchUserAndAssignments: (options: ISearchUsersAndAssignments) => Promise<any>;
  searchUsers: (
    applicationId: string,
    query: string,
    cb?: (data: any) => void,
    type?: string,
    limit?: number,
    offset?: number,
  ) => Promise<void>;
  searchAssignments: (options: ISearchAssignments) => Promise<void>;
  getAssignmentsUsers: (applicationId: string, assignmentId: string, userType: string) => Promise<void>;
  getAssignmentsUsersWithPagination: (
    applicationId: string,
    assignmentCode: string,
    userType: string,
    limit: number,
    offset: number,
  ) => Promise<void>;
  getAllWithPagination: (options: {
    applicationId: string;
    limit: number;
    offset: number;
    search?: string;
  }) => Promise<void>;
} => {
  const { data, loading, error, request, setData, setLoading } = useAxios();
  const {
    data: dataAssignments,
    loading: loadingAssignment,
    error: errorAssignments,
    requestAsync,
    cancel,
  } = useAxios();
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    dataAssignments && setAssignments(dataAssignments.items || [dataAssignments]);
  }, [dataAssignments]);

  const getAll = (applicationId: string): void => {
    request(`/applications/${applicationId}/users`);
  };

  const getAllWithCursor = (applicationId: string, cursor: string): void => {
    request(`/applications/${applicationId}/users?after=${cursor}`);
  };

  const get = (applicationId: string, userId: string): void => {
    request(`/applications/${applicationId}/users?search=id eq "${userId}"`);
  };

  const set = (applicationId: string, userId: string, resource: IUser) => {
    request(`/applications/${applicationId}/users/${userId}`, 'put', resource);
  };
  const getAssignments = async (applicationId: string, userId: string) => {
    await requestAsync(`/applications/${applicationId}/users/${userId}/assignments`);
  };

  const getAllWithPagination = async (options: IGetAllWithPagination) => {
    const { applicationId, limit, offset, search, after, cb, keySearch = 'search' } = options;
    setLoading(true);
    const callback = (data: any) => {
      data?.items && setData({ items: data.items, cursor: data?.cursor });
      setLoading(false);
    };

    const url = [`/applications/${applicationId}/users`];
    const params = [];
    search && params.push(`${keySearch}=${search}`);
    offset && params.push(`offset=${offset}`);
    limit && params.push(`limit=${limit}`);
    after && params.push(`after=${after}`);
    return requestAsync(`${url}?${params.join('&')}`, 'get', {}, cb || callback);
  };

  const searchUsers = (
    applicationId: string,
    query: string,
    cb?: (data: any) => void,
    type = 'users',
    limit?: number,
    offset?: number,
  ): Promise<void> => {
    const callback = (data: any) => {
      data && data.items && setData({ cursor: data?.cursor, items: data.items });
    };
    const queryString = [`search=${encodeURIComponent(query)}`];
    limit && queryString.push(`limit=${limit}`);
    offset && queryString.push(`offset=${offset}`);
    const url = `/applications/${applicationId}/${type}?${queryString.join('&')}`;
    return requestAsync(url, 'get', {}, cb || callback);
  };

  const searchAssignments = (options: ISearchAssignments): Promise<any> => {
    const { applicationId, limit, offset, cb, assignments: assignmentsArr } = options;
    const callback = (data: any) => {
      data && data.items && setData({ items: data.items, cursor: data.cursor });
      setLoading(false);
    };
    const search = assignmentsArr.map((assignment: string) => `assignments eq "${assignment}"`).join(' or ');

    const params = [`/applications/${applicationId}/users?search=${search}`];
    offset && params.push(`offset=${offset}`);
    limit && params.push(`limit=${limit}`);

    return requestAsync(params.join('&'), 'get', {}, cb || callback);
  };

  /**
   * @todo: Add to backend search for user and assignment
   */
  const searchUserAndAssignments = (options: ISearchUsersAndAssignments): Promise<any> => {
    const { applicationId, after, limit, search, next, cb } = options;
    setLoading(true);
    const callback = (data: any) => {
      data?.items && setData({ items: data.items, cursor: data?.cursor });
      setLoading(false);
    };
    const params = [`/applications/${applicationId}/users?search=${encodeURIComponent(search)}`];

    next && params.push(`after=${next}`);
    limit && params.push(`limit=${limit}`);
    after && params.push(`after=${after}`);

    return requestAsync(params.join('&'), 'get', {}, cb || callback);
  };

  const addAssignment = async (applicationId: string, userId: string, assignmentCode: string) => {
    return requestAsync(
      `/applications/${applicationId}/users/${userId}/assignments`,
      'post',
      {
        code: assignmentCode,
      },
      (_: any) => getAssignments(applicationId, userId),
    );
  };

  const addUsersToAssignment = async (
    applicationId: string,
    userIds: string[],
    assignmentCode: string,
    type = 'users',
  ): Promise<any> => {
    return await Promise.allSettled(
      userIds.map((userId: string) =>
        requestAsync(`/applications/${applicationId}/${type}/${userId}/assignments`, 'post', {
          code: assignmentCode,
        }),
      ),
    );
  };

  const removeAssignment = async (applicationId: string, userId: string, assignmentCode: string) => {
    return requestAsync(
      `/applications/${applicationId}/users/${userId}/assignments/${assignmentCode}`,
      'delete',
      {},
      (_: any) => getAssignments(applicationId, userId),
    );
  };

  const getAssignmentsUsers = async (applicationId: string, assignmentCode: string, userType: string) => {
    return requestAsync(
      `/applications/${applicationId}/assignments/${assignmentCode}/${userType}`,
      'get',
      {},
      (data: any) => data?.items && setData({ items: data.items, cursor: data?.cursor }),
    );
  };

  const getAssignmentsUsersWithPagination = async (
    applicationId: string,
    assignmentCode: string,
    userType: string,
    limit: number,
    offset: number,
  ) => {
    setLoading(true);
    return requestAsync(
      `/applications/${applicationId}/assignments/${assignmentCode}/${userType}?limit=${limit}&offset=${offset || 0}`,
      'get',
      {},
      (data: any) => {
        data?.items && setData({ items: data.items, cursor: data?.cursor });
        setLoading(false);
      },
    );
  };

  return {
    getAssignmentsUsers,
    data,
    assignments,
    error: error || errorAssignments,
    loading,
    loadingAssignment,
    addAssignment,
    addUsersToAssignment,
    getAll,
    getAllWithCursor,
    get,
    set,
    getAssignments,
    removeAssignment,
    searchAssignments,
    searchUserAndAssignments,
    searchUsers,
    cancel,
    getAssignmentsUsersWithPagination,
    getAllWithPagination,
  };
};
