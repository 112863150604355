import { Spin, Tag } from 'antd';

import useUsers, { IUser } from '../hooks/useUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

export default (props: { user: IUser }) => {
  const { applicationId = '' } = useParams();
  const { assignments: data, getAssignments, loadingAssignment } = useUsers();
  const [assignments, setAssignments] = useState<any[]>([]);

  useEffect(() => {
    if (props.user?.assignmentEntity) {
      setAssignments(props.user?.assignmentEntity);
    } else {
      getAssignments(applicationId, props.user.id);
    }
  }, [applicationId, props.user]);

  useEffect(() => {
    if (data?.length) {
      setAssignments(data.map(({ code = '' }) => code));
    }
  }, [data, loadingAssignment]);
  return (
    <>
      {loadingAssignment && !props.user?.assignments ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          {assignments.map((tag, i) => (
            <a href={`./assignments/${tag.code}/users`} key={`${tag.code}-${i}`}>
              <Tag color={tag.dynamic ? 'green' : 'geekblue'} style={{ margin: '5px' }} key={`${tag.code}-${i}`}>
                {tag.code}
              </Tag>
            </a>
          ))}
        </>
      )}
    </>
  );
};
